import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import LoginPage from './LoginPage.tsx';
import Playground from './generate_report.tsx';
import Tabledata from './TableData.tsx';
import Preferences from './Preferences.tsx';

const Main = () => {
    const [token, setToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const expectedToken = 'dnaddajsj'; // replace this with your expected token

    // Redirect to login page if token is not set
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, []);

    // Listen for changes to the token state and redirect to main page when token is set and matches the expected token
    useEffect(() => {
        if (token && token === expectedToken) {
            navigate('/');
        } else if (token) {
            setErrorMessage('Invalid token');
        }
    }, [token]);

    return (
        <Routes>
            <Route path='/' element={token ? <Playground/> : <LoginPage errorMessage={errorMessage} setToken={setToken} setErrorMessage={setErrorMessage} />} />
            <Route path="/table" element={token ? <Tabledata/> : <LoginPage errorMessage={errorMessage} setToken={setToken} setErrorMessage={setErrorMessage} />} />
            <Route path="/settings" element={token ? <Preferences/> : <LoginPage errorMessage={errorMessage} setToken={setToken} setErrorMessage={setErrorMessage} />} />
            <Route path="/login" element={<LoginPage errorMessage={errorMessage} setToken={setToken} setErrorMessage={setErrorMessage} />} />
        </Routes>
    );
}

export default Main;