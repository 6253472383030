import * as React from 'react';
import Main from './Main.tsx';
import { ThemeProvider as MuiThemeProvider, createTheme, AppBar, Toolbar, Typography } from '@mui/material';
import { useThemeContext, usePreferencesContext } from './ThemeContext.tsx';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export type LayoutChoice = 'horizontal' | 'vertical' | 'elementOnly';

const initialState = Object.freeze({
  layout: 'horizontal' as LayoutChoice,
  showingOptions: true,
  validateBeforeSubmit: true,
  validatePristine: false,
  disabled: false,
});

const App = () => {
  const [state, setState] = React.useState(initialState);
  const { darkMode } = useThemeContext();
  const { setUnits, units, dateFormat, timeFormat } = usePreferencesContext();
  const navigate = useNavigate();
 const navigateHome = () => {
  navigate('/'); // Assumes that your homepage is at the root
};
  const navigateToSettings = () => {
    navigate('/settings');
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const handleChangeOption = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: Array.isArray(value)
        ? value.reduce((acc, option) => ({ ...acc, [option]: !prevState[option] }), {})
        : value,
    }));
  };

  const handleToggleOptions = () => {
    setState((prevState) => ({ ...prevState, showingOptions: !prevState.showingOptions }));
  };

  const { layout, validateBeforeSubmit, validatePristine, showingOptions, disabled } = state;

  return (
    <MuiThemeProvider theme={theme}>
      <Box className="App">
        <AppBar position="static">
          <Toolbar>
            <Button
              onClick={navigateHome}
              color="inherit" // This will make the button text the same color as the AppBar
              sx={{
                textTransform: 'none', // This will remove the uppercase text transformation from the button
              }}
            >
              <Typography variant="h6" component="div">
                ActivTrace
              </Typography>
            </Button>
            <Box sx={{ flexGrow: 1 }} /> {/* This will push the Settings button to the right */}
            <Button
              variant="contained"
              color="secondary"
              onClick={navigateToSettings}
              sx={{
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              }}
            >
              Settings
            </Button>
          </Toolbar>
        </AppBar>
        <Main />
      </Box>
    </MuiThemeProvider>
  );
};


export default App;
