import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider, useTheme } from '@mui/material/styles';

import { useThemeContext } from './ThemeContext.tsx';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@mui/material';

const Preferences: React.FC = () => {
  const {
    darkMode,
    toggleDarkMode,
    units,
    setUnits,
    dateFormat,
    setDateFormat,
    timeFormat,
    setTimeFormat,
  } = useThemeContext();

  const theme = useTheme();
  const localTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const handleUnitsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUnits(event.target.value as 'imperial' | 'metric');
  };

  const handleDateFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDateFormat(event.target.value as string);
  };

  const handleTimeFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFormat(event.target.value as string);
  };

  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleDarkMode();
  };

  return (
    <MuiThemeProvider theme={localTheme}>
      <Paper style={{ padding: '2rem', backgroundColor: theme.palette.background.paper, minHeight: '100vh' }}>

        <Typography variant="h4">Preferences</Typography>
        <FormControl fullWidth variant="outlined" style={{ margin: '1rem 0' }}>
          <InputLabel>Units</InputLabel>
          <Select
            value={units}
            onChange={handleUnitsChange}
            label="Units"
          >
            <MenuItem value="imperial">Imperial</MenuItem>
            <MenuItem value="metric">Metric</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" style={{ margin: '1rem 0' }}>
          {/* <InputLabel>Date Format</InputLabel>
          <Select value={dateFormat} onChange={handleDateFormatChange} label="Date Format">
            <MenuItem value="MM/dd/yyyy">MM/dd/yyyy</MenuItem>
            <MenuItem value="dd/MM/yyyy">dd/MM/yyyy</MenuItem>
            <MenuItem value="yyyy/MM/dd">yyyy/MM/dd</MenuItem>
          </Select> */}
        </FormControl>
        <FormControl fullWidth variant="outlined" style={{ margin: '1rem 0' }}>
          {/* <InputLabel>Time Format</InputLabel>
          <Select value={timeFormat} onChange={handleTimeFormatChange} label="Time Format">
            <MenuItem value="hh:mm A">12-hour (hh:mm A)</MenuItem>
            <MenuItem value="HH:mm">24-hour (HH:mm)</MenuItem>
          </Select> */}
        </FormControl>
        {/* <FormControlLabel
          control={<Switch checked={darkMode} onChange={handleDarkModeChange} />}
          label="Dark Mode"
        /> */}
      </Paper>
    </MuiThemeProvider>
  );
};

export default Preferences;
