import * as React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

interface PreferencesContextType {
  darkMode: boolean;
  toggleDarkMode: () => void;
  setUnits: (newUnits: Units) => void;
  units: Units;
  setDateFormat: (newDateFormat: string) => void;
  dateFormat: string;
  setTimeFormat: (newTimeFormat: string) => void;
  timeFormat: string;
}

interface Units {
  distance: 'meters' | 'feet';
  duration: 'seconds' | 'minutes';
}

const PreferencesContext = React.createContext<PreferencesContextType>({
  darkMode: false,
  toggleDarkMode: () => {},
  setUnits: () => {},
  setDateFormat: () => {},
  setTimeFormat: () => {},
  units: { distance: 'meters', duration: 'seconds' },
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
});

export const usePreferencesContext = () => React.useContext(PreferencesContext);

export const ThemeProvider: React.FunctionComponent = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [units, _setUnits] = useState<Units>({ distance: 'meters', duration: 'seconds' });
  const [dateFormat, _setDateFormat] = useState('YYYY-MM-DD');
  const [timeFormat, _setTimeFormat] = useState('HH:mm');

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    const storedUnits = localStorage.getItem('units');
    const storedDateFormat = localStorage.getItem('dateFormat');
    const storedTimeFormat = localStorage.getItem('timeFormat');

    if (storedDarkMode) setDarkMode(JSON.parse(storedDarkMode));
    if (storedUnits) _setUnits(JSON.parse(storedUnits));
    if (storedDateFormat) _setDateFormat(storedDateFormat);
    if (storedTimeFormat) _setTimeFormat(storedTimeFormat);
  }, []);

  const toggleDarkMode = () => {
    localStorage.setItem('darkMode', JSON.stringify(!darkMode));
    setDarkMode(!darkMode);
  };

  const setUnits = (newUnits: Units) => {
    localStorage.setItem('units', JSON.stringify(newUnits));
    _setUnits(newUnits);
  };

  const setDateFormat = (newDateFormat: string) => {
    localStorage.setItem('dateFormat', newDateFormat);
    _setDateFormat(newDateFormat);
  };

  const setTimeFormat = (newTimeFormat: string) => {
    localStorage.setItem('timeFormat', newTimeFormat);
    _setTimeFormat(newTimeFormat);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <PreferencesContext.Provider
        value={{
          darkMode,
          toggleDarkMode,
          setUnits,
          setDateFormat,
          setTimeFormat,
          units,
          dateFormat,
          timeFormat,
        }}
      >
        {children}
      </PreferencesContext.Provider>
    </MuiThemeProvider>
  );
};

export const useThemeContext = () => {
  const context = React.useContext(PreferencesContext);
  return context;
};
