// /* eslint-env node, browser */

// import * as React from 'react';
// import {render} from 'react-dom';
// import  ReactDOM  from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App.tsx';
// // import { StyledEngineProvider } from '@mui/material/styles';

// // var bat = App;
// ReactDOM.render((
//     <BrowserRouter>
//       <App /> {/* The various pages will be displayed by the `Main` component. */}
//     </BrowserRouter>
//     ), document.getElementById('root')
//   );
// // render(<App/>, document.getElementById('root'));
import * as React from 'react';
import { render } from 'react-dom';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import { ThemeProvider } from './ThemeContext.tsx';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <App /> {/* The various pages will be displayed by the `Main` component. */}
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
