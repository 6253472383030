import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
const database = sessionStorage.getItem("database");

const LoginPage = ({ errorMessage, setToken, setErrorMessage }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async () => {
        const encryptedPassword = CryptoJS.AES.encrypt(password, 'secret key 123').toString();
        try {
            const response = await axios.post(`${database}/login`, {
                username,
                password: encryptedPassword,
            });
            if (response.data.token) {
                setToken(response.data.token);
                setErrorMessage(''); // clear any previous error message
            } else {
                setErrorMessage('Wrong username or password');
            }
        } catch (error) {
            setErrorMessage('Trouble connecting to the server');
        }
    };

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2}
                border="1px solid #ccc"
                borderRadius={4}
                width="80%" // Set the width to 80% of the viewport
                maxWidth="500px" // Set the maximum width to 500px
                margin="auto" // Center the box horizontally
                mt={4} // Add margin-top for spacing
            >
                <TextField
                    label="Username"
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    margin="normal"
                />
                <Button variant="contained" onClick={handleLogin} color="primary">
                    Login
                </Button>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            </Box>
        </Box>
    );
};

export default LoginPage;