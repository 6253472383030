import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Button, Box, TextField, MenuItem, LinearProgress } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { format, parseISO } from "date-fns";
import { createMRTColumnHelper } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const createColumn = (id, label, options) => ({
  id,
  label,
  ...options,
});

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    size: 140,
    
    columnFilterModeOptions: ['betweenInclusive', 'greaterThan', 'lessThan'],
    
    filterFn: 'betweenInclusive',
    // Filter: ({ column }) => {
    //   return (
    //     <div style={{ display: 'flex', gap: '0.5rem' }}>
    //       <TextField
    //         label="Min"
    //         type="number"
    //         value={column.getFilterValue()?.[0] || ''}
    //         onChange={(e) => column.setFilterValue((old) => [e.target.value, old?.[1]])}
    //         variant="standard"
    //       SelectProps={{ style: { width: '200px' } }} // Increase the height of the select dropdown

    //         size="small"
    //       />
    //       <TextField
    //         label="Max"
    //         type="number"
    //         value={column.getFilterValue()?.[1] || ''}
    //         onChange={(e) => column.setFilterValue((old) => [old?.[0], e.target.value])}
    //         variant="standard"
    //       SelectProps={{ style: { width: '200px' } }} // Increase the height of the select dropdown

    //         size="small"
    //       />
    //     </div>
    //   );
    // },
    //filterFn: 'between',

  }),
  columnHelper.accessor('time', {
    accessorFn: (row) => new Date(row.time), // convert to Date for sorting and filtering
    id: 'time',
    header: 'Exposure Time (yyyy-MM-dd HH:mm:ss Central Time)',
    filterVariant: 'datetime-range',
    filterFn: 'betweenInclusive',
    filterSelectOptions:[{ label: 'Between', value: 'betweenInclusive' }, { label: 'Before', value: 'lessThan' }, { label: 'After', value: 'greaterThan' }],
    sortingFn: 'datetime',
    Cell: ({ cell }) => format(cell.getValue(), 'yyyy-MM-dd HH:mm:ss'), // render Date as a string
    columnFilterModeOptions: ['betweenInclusive', 'greaterThan', 'lessThan'],

    Header: ({ column }) => <em>{column.columnDef.header}</em>, // custom header markup
    muiFilterTextFieldProps: {
      sx: {
        minWidth: '250px',
      },
    },
  }),
  columnHelper.accessor('exposure', {
    header: 'Degree of Exposure',
    size: 200,
    Filter: ({ column }) => {
      return (
        <TextField
          select
          label="Degree of Exposure"
          value={column.getFilterValue() || ''}
          onChange={(e) => column.setFilterValue(e.target.value)}
          variant="standard"
          size="small"
          SelectProps={{ style: { width: '200px' } }} // Increase the height of the select dropdown
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="1">1st Degree</MenuItem>
          <MenuItem value="2">2nd Degree</MenuItem>
        </TextField>
      );
    },
  }),
];

const TableData = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    var rets = localStorage.getItem("data");
    if (rets == null || rets === "0") {
      setError(true);
      setLoading(false);
      return;
    }
  
    var data = JSON.parse(rets);
    const token = sessionStorage.getItem("token");
    const database = sessionStorage.getItem("database");
  
    axios
      .post(`${database}/runContactTrace?token=${token}`, { data })
      .then(res => {
        setDatas(formatData(res));
        setLoading(false);
        setError(false);
      })
      .catch(error => {
        console.error(error);
        setError(true);
        setLoading(false);
      });
  };

  const formatData = (res) => {
    var ret = [];
    for (var i = 0; i < res.data.table_id.length; i++) {
      var formattedTime = format(new Date(res.data.table_time_of_exposure[i]), `yyyy-MM-dd HH:mm:ss`);
      var temp = { id: res.data.table_id[i], time: formattedTime, exposure: res.data.table_exposure_degree[i] }
      ret.push(temp);
    }
    return ret;
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF('p', 'pt', 'a4');
    const formData = JSON.parse(localStorage.getItem("formData"));
    const distanceUnit = localStorage.getItem("distanceUnit");
    const durationUnit = localStorage.getItem("durationUnit");
    const dateFrom = new Date(formData['date-from']);
    dateFrom.setDate(dateFrom.getDate() + 1);
    const formattedDateFrom = format(dateFrom, 'MMMM do, yyyy');
  
    const dateTo = new Date(formData['date-to']);
    dateTo.setDate(dateTo.getDate() + 1);
    const formattedDateTo = format(dateTo, 'MMMM do, yyyy');
  
    doc.setFontSize(20);
    doc.text(`ActiveTrace Positive Contact Report, `, 40, 40);
    doc.text(`${formattedDateFrom} - ${formattedDateTo}`, 40, 60);
    doc.setFontSize(16);
    doc.text('Contact Trace Parameters:', 40, 100);
    doc.setFontSize(12);
    doc.text(`Caregiver/Patient ID: ${formData.target}`, 40, 130);
    doc.text(`Degree (1st or 2nd degree): ${formData.degree}`, 40, 150);
    doc.text(`Distance: ${formData.distance} ${distanceUnit}`, 40, 170);
    doc.text(`Duration: ${formData.duration} ${durationUnit}`, 40, 190);
  
    doc.setFontSize(14);
    doc.text('Possible Infected Contacts', 40, 220);
    
    const tableData = rows.map(row => [
      row.original.id, 
      format(new Date(row.original.time), 'yyyy-MM-dd HH:mm:ss'), 
      row.original.exposure
    ]);
    const headers = columns.map(c => c.header);
  
    autoTable(doc, {
      startY: 240,
      head: [headers],
      body: tableData,
      styles: {
        fontSize: 10,
      },
    });
  
    doc.save('report.pdf');
  };

  const table = useMaterialReactTable({
    
    columns,
    data: datas,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true }, //show filters by default
    enableRowSelection: true,
    enableColumnFilterModes: true, //enable changing filter mode for all columns unless explicitly disabled in a column def
    columnFilterDisplayMode: 'subheader',
    paginationDisplayMode: 'pages',
   // defaultColumnFilterMode: 'between',
    positionToolbarAlertBanner: 'bottom',
 
   
    renderTopToolbarCustomActions: ({ table }) => (
     
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <Box>
      <Container sx={{ display: 'flex', alignItems: 'left', marginTop: 3, marginBottom: 3 }}>
        {/* <Typography variant="h6" component="div" style={{marginTop: 10, marginRight: 30}}>
          Table Data
        </Typography> */}
      </Container>
      {/* <Box display="flex" justifyContent="center">
      <Typography variant="h6">
        Potential Infected Contacts
      </Typography>
    </Box> */}
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Box>
          <p>There was an error while fetching data. Please try again.</p>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Retry
          </Button>
        </Box>
      ) : (
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box ref={tableRef}>
        <Box display="flex" justifyContent="center">
        <Typography variant="h5">
        Potential Infected Contacts List
        </Typography>
        </Box>
        <br></br>
          <MaterialReactTable table={table} 
          messages={{
            noData: 'No contacts found',
          }}/>
        </Box>
        <br></br>
        </LocalizationProvider>
      )}
    </Box>
  );
}

export default TableData;
