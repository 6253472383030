import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
// import { styledEngine } from '@mui/system/styleFunctionSx/styledEngine';
// import { CssBaseline } from '@mui/material';
import {
  
  Container,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  CssBaseline,
} from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/system';
import moment from 'moment';

import { LayoutChoice } from './App';
import { useThemeContext, usePreferencesContext } from './ThemeContext.tsx';
import { useDeepCompareEffect } from 'use-deep-compare'; 

interface Props {
  disabledChoice: boolean;
  layoutChoice: LayoutChoice;
  validateBeforeSubmitChoice: boolean;
  validatePristineChoice: boolean;
}

const Playground: React.FunctionComponent<Props> = ({
  disabledChoice,
  layoutChoice,
  validateBeforeSubmitChoice,
  validatePristineChoice,
}) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [selectedDatabase, setSelectedDatabase] = useState(
    () => JSON.parse(localStorage.getItem('formData') || '{}').selectedDatabase || ''
  );
  const { darkMode, toggleDarkMode } = useThemeContext();
  const {
    units,
    setUnits,
    dateFormat,
    setDateFormat,
    timeFormat,
    setTimeFormat,
  } = usePreferencesContext();

  useDeepCompareEffect(() => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        distanceUnit: units.distance,
        durationUnit: units.duration,
      };
    });
  }, [units]);
  const handleDateChange = (dateValue, fieldName) => {
    const formattedDate = format(dateValue, dateFormat);
    setFormData({ ...formData, [fieldName]: formattedDate });
  }
  
  useEffect(() => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        ['date-from']: dateFormat.toLowerCase(),
        ['date-to']: dateFormat.toLowerCase(),
      };
    });
  }, [dateFormat]);
  useEffect(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFormData(parsedData);
      setSelectedDatabase(parsedData.selectedDatabase || '');
    }
  }, []);
  useEffect(() => {
    const dateFrom = localStorage.getItem("date-from");
    const dateTo = localStorage.getItem("date-to");
  
    if (dateFrom && dateTo) {
      setFormData(prevState => ({
        ...prevState,
        'date-from': dateFrom,
        'date-to': dateTo,
      }));
    }
  }, []);
  const handleDatabaseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDatabase(event.target.value as string);
  };

  const routeChange = () => {
    let path = 'table';
    navigate(path);
  };

  const formRef = useRef<HTMLFormElement>(null);

  const resetForm = (): void => {
    console.log('Reset called');
    if (formRef.current) {
      formRef.current.reset();
    }
    setSelectedDatabase("10_persons_0_stationary.csv")
    setFormData({}); // Clear the formData state
  };
  const distanceUnits = units === 'metric' ? ['meters', 'kilometers'] : ['feet', 'yards'];
  const durationUnits = ['seconds', 'minutes'];
 // Check if formData is empty
// if (formData['date-from'] === 'yyyy-mm-dd' || formData['date-to'] === 'yyyy-mm-dd' ) {
//   // Set default values
//   const dateFrom = moment("01-01-2014", "DD-MM-YYYY").format("YYYY-MM-DD");
//   const dateTo = moment("01-01-2024", "DD-MM-YYYY").format("YYYY-MM-DD");
//   setFormData({ ...formData, 'date-from': dateFrom, 'date-to': dateTo });
//   localStorage.setItem("date-from", dateFrom);
//   localStorage.setItem("date-to", dateTo);
// }
useEffect(() => {
  let storedFormData = JSON.parse(localStorage.getItem('formData'));

  const dateFrom = storedFormData && storedFormData['date-from'] ? storedFormData['date-from'] : moment("01-01-2014", "DD-MM-YYYY").format("YYYY-MM-DD");
  const dateTo = storedFormData && storedFormData['date-to'] ? storedFormData['date-to'] : moment("01-01-2024", "DD-MM-YYYY").format("YYYY-MM-DD");

  setFormData(prevFormData => {
    const updatedFormData = { ...prevFormData, 'date-from': dateFrom, 'date-to': dateTo };
    localStorage.setItem("formData", JSON.stringify(updatedFormData));
    return updatedFormData;
  });
}, []);
  if (!formData['distanceUnit'] || !distanceUnits.includes(formData['distanceUnit'])) {
    const defaultDistanceUnit = distanceUnits[0]; // replace with your distanceUnits array
    setFormData({ ...formData, ['distanceUnit']: defaultDistanceUnit });
    localStorage.setItem("distanceUnit", defaultDistanceUnit);
  }
  // if (!formData['durationUnit'] || !units.includes(formData['durationUnit'])) {
  //   const defaultUnit = durationUnits[0]; // replace with your units array
  //   setFormData({ ...formData, ['durationUnit']: defaultUnit });
  //   localStorage.setItem("durationUnit", defaultUnit);
  // }
  if (!formData['durationUnit'] || !durationUnits.includes(formData['durationUnit'])) {
    const defaultDurationUnit = durationUnits[0]; // replace with your durationUnits array
    setFormData({ ...formData, ['durationUnit']: defaultDurationUnit });
    localStorage.setItem("durationUnit", defaultDurationUnit);
  }
  
  const submitForm = (data): void => {
    console.log('Submitting form');

    // Create a copy of formData with the necessary conversions
    const convertedData = { ...formData };

    switch (formData.distanceUnit) {
      case 'feet':
        convertedData.distance = formData.distance * 0.3048; // Convert to meters
        break;
      case 'yards':
        convertedData.distance = formData.distance * 0.9144; // Convert to meters
        break;
      case 'kilometers':
        convertedData.distance = formData.distance * 1000; // Convert to meters
        break;
      default: // 'meters'
        convertedData.distance= formData.distance;
    }
    switch (formData.durationUnit) {
      case 'minutes':
        convertedData.duration = formData.duration * 60; // Convert to seconds
        break;
      default: // 'seconds'
        convertedData.duration = formData.duration;
    }

    formData.selectedDatabase = selectedDatabase;
    convertedData.selectedDatabase = selectedDatabase;
    convertedData.distance *= 2;
    const fromDate = new Date(formData['date-from']);
    const toDate = new Date(formData['date-to']);

    fromDate.setDate(fromDate.getDate() - 1);
    toDate.setDate(toDate.getDate() + 1);

    convertedData['date-from'] = fromDate;
    convertedData['date-to'] = toDate;

    // Remove the unit fields from the stored data
    delete convertedData.distanceUnit;
    delete convertedData.durationUnit;

    localStorage.setItem('formData', JSON.stringify(formData)); // Save original formData
    localStorage.setItem('convertedFormData', JSON.stringify(convertedData)); // Save converted data separately
    localStorage.setItem('data', JSON.stringify(convertedData));
    routeChange();
    console.log(convertedData);
  };

  const theme = useTheme();
  const localTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    mode: darkMode ? 'dark' : 'light',
  },
});

  


  return (
    <MuiThemeProvider theme={localTheme}>
       <CssBaseline />
      <Container maxWidth="md">
        <br></br>
        <br></br>
        <Typography variant="h4" align="center" gutterBottom>
          New Contact Trace Form
        </Typography>
     
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <form
              ref={formRef}
              className="custom-classname-is-rendered"
             

            onSubmit={(e) => {
          
            e.preventDefault();
            submitForm(formData);
            }}>
            {/* onValidSubmit={submitForm}
            layout={layoutChoice}
            className="custom-classname-is-rendered"
            validateBeforeSubmit={validateBeforeSubmitChoice}
            validatePristine={validatePristineChoice}
            disabled={disabledChoice}
            ref={formRef}> */}
            <TextField
              name="target"
              label="Caregiver/Patient ID"
              type="text"
              placeholder="Patient Id number of Caregiver"
              required
              fullWidth
              sx={{ mt: 1, mb: 1 }}
              value={formData.target || ''}
              onChange={(e) => setFormData({ ...formData, target: e.target.value })}
              color={formData.target ? 'success' : 'primary' }
              focused={!!formData.target}
              type="number"
              InputProps={{ inputProps: { min: 1, max: 100 } }}
              helperText="Id number of the infected individual. This should be a number between 1 and the number of people in the database"
              error={formData.target && (formData.target < 1 || formData.target > 100 || formData.target.includes('.'))}
              helperText={formData.target && (formData.target < 1 || formData.target > 100 || formData.target.includes('.')) ? "ID must be between 1 and 100 and an integer" : "Id number of the infected individual. This should be a number between 1 and the number of people in the database"}
           />
            <Typography variant="subtitle1">Date Range</Typography>
            {/* <Typography variant="subtitle1">Date</Typography> */}
            <InputLabel htmlFor="date-from">From</InputLabel>
              <TextField
                name="date-from"
                type="date"
               // defaultValue="2014-01-01"
                helperText="Test data is available for the date of 01-Mar-2022"

                required
                fullWidth
                inputProps={{
                  // min: '2021-01-01',
                  max: '2099-12-31',
                  // pattern: dateFormat.toLowerCase(),
                }}
                sx={{ mt: 1, mb: 1 }}
                value={formData[`date-from`]}
                color={'success'}
                focused={!!formData.datefrom}
                onChange={(e) =>
                  setFormData({ ...formData, ['date-from']: e.target.value })
                }
               


              />
              <InputLabel htmlFor="date-to">To</InputLabel>
              <TextField
                name="date-to"
                type="date"

              //  defaultValue="2024-01-01"
                required
                fullWidth
                inputProps={{
                  // min: '2021-01-01',
                  max: '2099-12-31',
                  pattern: dateFormat.toLowerCase(),

                }}
                sx={{ mt: 1, mb: 1 }}
                value={formData[`date-to`]}
                color={formData.dateto ? 'success' : 'primary' }
                focused={!!formData.dateto}
                onChange={(e) =>
                  setFormData({ ...formData, ['date-to']: e.target.value })
                }
              />
 
 <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    name="distance"
                    label={`Distance (${formData.distanceUnit})`}
                    type="text"
                    placeholder="Enter distance value"
                    required
                    fullWidth
                    helperText="CDC recommends 6 feet or 2 meters for contact tracing."
                    sx={{ mt: 1, mb: 1 }}
                    value={formData.distance || ''}
                    
                    color={formData.distance ? 'success' : 'primary' }
                    focused={!!formData.distance}
                    type="number"
                    inputProps={{
                      // min: '2021-01-01',
                      min: '0',
                      step: '0.01'
    
                    }}
                   

                    onChange={(e) =>
                      setFormData({ ...formData, distance: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    {/* <InputLabel htmlFor="distance-unit">Unit</InputLabel> */}
                    <FormControl fullWidth>
    <InputLabel htmlFor="distance-unit">Unit</InputLabel>
    <Select
      required
      value={formData.distanceUnit  } 
      color={formData.distanceUnit ? 'success' : 'primary' }
      focused={!!formData.distanceUnit}
   
     
      sx={{ mt: 1, mb: 1 }}
      onChange={(e) => {
        const newValue = e.target.value;
        setFormData((prevState) => ({
          ...prevState,
          distanceUnit: newValue,
        }));
        localStorage.setItem("distanceUnit", newValue);
      }}
      inputProps={{
        name: 'distance-unit',
        id: 'distance-unit',
      }}
    >
      {distanceUnits.map((unit) => (
        <MenuItem value={unit}>{unit}</MenuItem>
      ))}
    </Select>
  </FormControl>

                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    name="duration"
                    label={`Duration (${formData.durationUnit})`}
                    type="text"
                    placeholder="Enter duration value"
                    helperText="CDC recommends 15 minutes."
                    color={formData.duration ? 'success' : 'primary' }
                    focused={!!formData.duration}
                    type="number"
                    required
                    inputProps={{
                      // min: '2021-01-01',
                      min: '0',
                      step: '0.01'
    
                    }}
                   
                    required
                    fullWidth
                    sx={{ mt: 1, mb: 1 }}
                    value={formData.duration }
                    onChange={(e) =>
                      setFormData({ ...formData, duration: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="duration-unit">Unit</InputLabel>
                    <Select
                      required
                      value={formData.durationUnit || units.duration}
                      color={formData.durationUnit ? 'success' : 'primary' }
                      focused={!!formData.durationUnit}
                      type="number"
                      
                      focus={true}
                      required
                     
                      sx={{ mt: 1, mb: 1 }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          durationUnit: e.target.value,
                        })
                      }
                      inputProps={{
                        name: 'duration-unit',
                        id: 'duration-unit',
                      }}
                
                    >
                      <MenuItem value={'seconds'}>seconds</MenuItem>
                      <MenuItem value={'minutes'}>minutes</MenuItem>
                  
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

            {/* </Grid> */}
            <TextField
              name="degree"
              label="Contact Degree"
              type="text"
              placeholder="1st or 2nd degree"
              helperText="Id number of the infected individual. This should be a number between 1 and the number of people in the database"
              
              required
              fullWidth
              sx={{ mt: 1, mb: 1 }}
              value={formData.degree || ''}
              
              onChange={(e) =>
                setFormData({ ...formData, degree: e.target.value })
              }
              color={formData.degree ? 'success' : 'primary' }
              focused={!!formData.degree}
              type="number"

              InputProps={{ inputProps: { min: 1, max: 2, step: 1 } }}
              error={formData.degree && (formData.degree < 1 || formData.degree > 2 || formData.degree.includes('.'))}
              helperText={formData.degree && (formData.degree < 1 || formData.degree > 2 || formData.degree.includes('.')) ? "Degree must be 1 or 2" : ""}
            />
            <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
              <InputLabel id="database-label">Database</InputLabel>
              <Select
                labelId="database-label"
                id="database-select"
                value={selectedDatabase}
                label="Database"
                onChange={handleDatabaseChange}
              >
                {/* Add the database options */}
                <MenuItem value="10_persons_0_stationary.csv">
                                    10 person database
                </MenuItem>
                <MenuItem value="20_persons_0_stationary.csv">
                  20 person database
                </MenuItem>'
                <MenuItem value="30_persons_0_stationary.csv">
                                    30 person database
                </MenuItem>
                <MenuItem value="40_persons_0_stationary.csv">
                  40 person database
                </MenuItem>                
                <MenuItem value="50_persons_0_stationary.csv">
                                    50 person database
                </MenuItem>
                <MenuItem value="60_persons_0_stationary.csv">
                  60 person database
                </MenuItem>
                <MenuItem value="70_persons_0_stationary.csv">
                                    70 person database
                </MenuItem>
                <MenuItem value="80_persons_0_stationary.csv">
                  80 person database
                </MenuItem>
                <MenuItem value="90_persons_0_stationary.csv">
                                    90 person database
                </MenuItem>
                <MenuItem value="100_persons_0_stationary.csv">
                  100 person database
                </MenuItem>
                {/* Add more

                {/* Add more options as required */}
              </Select>
            </FormControl>
            <br></br>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                  type="reset"
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  // formNoValidate
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
         </form>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
      </Container>
     </MuiThemeProvider>
  );
};

export default Playground;

